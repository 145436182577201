<template>
  <CRow>
    <CModal :title="
      submitType === 'Update'
        ? $lang.companytype.crud.edit
        : $lang.companytype.crud.create
    " size="lg" :show.sync="largeModal" :no-close-on-backdrop="true" color="primary">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{
        err_msg
      }}
      </CAlert>

      <CForm novalidate @submit.prevent="onSubmit">
        <CCardBody>
          <div class="form-group">
            <label class="form__label">{{ $lang.companytype.form.industry }}
              <required_span />
            </label>
            <v-select id="industry" :options="industryOptions" v-model="companytype.industry"></v-select>
            <small class="error" v-if="$v.companytype.$error && !$v.companytype.industry.required">{{
              $lang.companytype.validation.required.industry
            }}</small>
          </div>
          <div class="form-group" :class="{ 'form-group--error': $v.companytype.title.$error }">
            <label class="form__label">{{ $lang.companytype.form.title }}
              <required_span />
            </label>
            <input :placeholder="$lang.companytype.form.title" class="form-control" :maxlength="maxlength.title"
              v-model="companytype.title" />
            <small class="error" v-if="
              $v.companytype.title.$error && !$v.companytype.title.required
            ">{{ $lang.companytype.validation.required.title }}</small>
            <small class="error" v-if="
              $v.companytype.title.$error && !$v.companytype.title.minLength
            ">{{ $lang.companytype.validation.minLength.title }}
              {{ $v.companytype.title.$params.minLength.min }}
              {{ $lang.companytype.validation.common.letter }}</small>
          </div>
          <div class="form-group">
            <label class="form__label">{{ $lang.companytype.form.type }}
              <required_span />
            </label>
            <v-select id="type" :options="typeOptions" v-model="companytype.type"></v-select>
            <small class="error" v-if="$v.companytype.$error && !$v.companytype.type.required">{{
              $lang.companytype.validation.required.type
            }}</small>
          </div>
          <div class="form-group" :class="{ 'form-group--error': '' }">
            <label class="form__label">{{ $lang.companytype.form.isActive }}
            </label>
            <input type="checkbox" :placeholder="$lang.companytype.form.isActive" :value="1" @change="changedIsActive"
              v-model="companytype.isActive" />
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton type="submit" :disabled="submitted" size="sm" color="primary">
            <CIcon name="cil-check-circle" />
            {{ $lang.buttons.general.crud.submit }}
          </CButton>
          &nbsp;
        </CCardFooter>
      </CForm>
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none">Discard
        </CButton>
        <CButton @click="darkModal = false" color="success" style="display: none">Accept
        </CButton>
      </template>
    </CModal>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.companytype.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButton color="primary" v-on:click="createTableRow" v-if="checkPermission('create-companyType')"
                  style="margin-right: 10px">
                  {{ $lang.buttons.general.crud.create }}
                </CButton>
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf"
                    v-on:click="exports(self, 'pdf', module, 'Company Type')">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data"
                    :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert :color="messageColor" closeButton :show.sync="dismissCountDownS" fade>{{ alertMessage }}
          </CAlert>
          <v-server-table :columns="columns" url="/company-types/list" :options="options" ref="myTable"
            @loaded="exports(self, 'Excel', module, 'CompanyType')">
            <template #status="data">
              <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
            </template>
            <template #title="data">
              <span :title="data.row.title">{{
                trimfunction(data.row.title, 20)
              }}</span>
            </template>
            <template #actions="data">
              <CButton color="success" v-c-tooltip="$lang.buttons.general.crud.edit"
                v-on:click="editTableRow(data.row.id)" v-if="checkPermission('edit-companyType')">
                <i class="fas fa-pencil-alt"></i>
              </CButton>
              <CButton color="danger" v-c-tooltip="$lang.buttons.general.crud.delete"
                v-on:click="deletePopup(data.row.id)" v-if="checkPermission('delete-companyType')">
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import { companytype, industry } from "/src/store/url.js";
import { Mixin } from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import { ServerTable } from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import { minLength, required } from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import VueJsonToCsv from "vue-json-to-csv";
import { Maxlength } from "../../store/maxlength";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "CompanySize",
  mixins: [Mixin],
  components: {
    required_span,
    VueJsonToCsv,
  },
  data() {
    return {
      moduleIndustry: industry,
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      isActiveFields: 1,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "",
      messageColor: "success",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      industryOptions: [],
      typeOptions: [
        {
          'id': 'Company',
          'label': 'Company',
        },
        {
          'id': 'Individual',
          'label': 'Individual',
        },
      ],
      activePage: 1,
      module: companytype,
      companytype: {
        title: "",
        type: "",
        industry: ""
      },
      maxlength: {
        title: Maxlength.companytype.title,
      },
      columns: ["industry", "title", "type", "status", "actions"],
      data: [],
      options: {
        headings: {
          title: this.$lang.companytype.table.title,
          type: this.$lang.companytype.table.type,
          status: this.$lang.common.table.isActive,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["title", "type"],
        sortable: ["title", "type"],
        filterable: ["title", "type"],
      },
    };
  },
  validations: {
    companytype: {
      title: {
        required,
        minLength: minLength(1),
      },
      type: {
        required,
      },
    },
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
    axios.get(this.listUrlApi(this.moduleIndustry)).then((response) => {
      response.data.data.map(function (value, key) {
        if (value.industryId === "") {
          self.industryOptions.push({ value: value.id, label: value.industry });
        }
      });
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.companytype.isActive = 1;
      } else {
        self.companytype.isActive = 0;
      }
    },
    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if accepted
          let self = this;
          axios
            .delete(this.deleteUrlWeb(this.module, item), self)
            .then(function (response) {
              if (response.data.status === 200) {
                self.$refs.myTable.refresh();
                self.alertMessage = response.data.message;
                self.messageColor = "success";
                self.dismissCountDownS = 10;
              } else {
                self.$refs.myTable.refresh();
                self.alertMessage = response.data.message;
                self.messageColor = "danger";
                self.dismissCountDownS = 10;
              }
            }).catch(function (error) {
              let data;
              if (error.response.data.error) {
                data = error.response.data.error.toString();
              } else {
                data = error.response.data.message;
              }
              self.alertMessage = data;
              self.messageColor = "danger";
              self.dismissCountDown = 10;
              self.submitted = false;
            });
        }
      });
    },
    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.companytype.title = "";
      self.companytype.isActive = 1;
      self.companytype.type = {};
      self.largeModal = true;
      //this.$router.push({name: 'Country Create'})
    },
    editTableRow(item) {
      let self = this;
      self.$v.$reset();
      self.submitType = "Update";
      let id = item;
      self.editId = item;
      axios
        .get(this.viewUrlApi(this.module, id), self.model)
        .then(function (response) {
          if (response) {
            let responseData = response.data.data;
            self.companytype.title = responseData.title;
            self.companytype.type = {
              'id': responseData.type,
              'label': responseData.type,
            };
            self.companytype.isActive = responseData.isActive;
            self.companytype.industry = {
              'value': responseData.industryId,
              'label': responseData.industryName,
            };
          } else {
            self.data = [];
          }
        });

      // this.$router.push({path: `country/edit/${(item)}`})
      self.largeModal = true;
    },
    rowClicked(item) {
      this.$router.push({ path: `companytype/view/${item}` });
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    loadData() {
      let self = this;
      axios
        .get(this.listUrlApi(this.module), self.model)
        .then(function (response) {
          if (response) {
            self.data = response.data;
          } else {
            self.data = [];
          }
        });
    },
    onSubmit() {
      let self = this;
      this.$v.companytype.$touch();
      if (this.$v.companytype.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";

        if (this.submitType === "Update") {          
          setTimeout(() => {
            let self = this;
            const postData = {
              title: self.companytype.title,
              type: self.companytype.type.label,
              isActive: self.companytype.isActive,
              industry: self.companytype.industry.value,
            };
            axios
              .post(this.updateUrlweb(this.module, self.editId), postData)
              .then(function (response) {
                self.submitted = false; //Enable Button
                if (response.data.code === 200) {
                  self.largeModal = false;
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "success";
                  self.dismissCountDownS = 10;
                } else {
                  self.err_msg = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDown = 10;
                }
              })
              .catch(function (error) {
                let data;
                if (error.response.data.error) {
                  data = error.response.data.error.toString();
                } else {
                  data = error.response.data.message;
                }
                self.err_msg = data;
                self.dismissCountDown = 10;
                self.submitted = false; //Enable Button
              });
          }, 500);
        } else {
          setTimeout(() => {
            let self = this;
            const postData = {
              title: self.companytype.title,
              type: self.companytype.type.label,
              industry: self.companytype.industry.value,
              isActive: self.companytype.isActive,
            };
            axios
              .post(this.createUrlWeb(this.module), postData)
              .then(function (response) {
                if (response.data.code === 200) {
                  self.alertMessage = response.data.message;
                  self.messageColor = "success";
                  self.dismissCountDownS = 10;
                  self.submitted = false; //Enable Button
                  self.largeModal = false;
                  self.$refs.myTable.refresh();
                } else {
                  self.err_msg = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDown = 10;
                  self.submitted = false; //Enable Button
                }
              })
              .catch(function (error) {
                let data;
                if (error.response.data.error) {
                  data = error.response.data.error.toString();
                } else {
                  data = error.response.data.message;
                }
                self.err_msg = data;
                self.dismissCountDown = 10;
                self.submitted = false; //Enable Button
              });
          }, 500);
        }
      }
    },
  },
};
</script>
